import React from "react";

import TagLine from "images/tagline@2x.png";

export default function TopSection() {
  return (
    <section id="top-section" className="top-section">
      <div className=" top-section-bg">
        <div className="container text-center">
          <div className="row top">
            <div className="col tagline">
              <img src={TagLine} alt="Read, Believe, Imagine" />
            </div>
          </div>
          <div className="row content">
            <div className="col h4 text-muted">
              At Henry and Jeffers Publishing House,
              <br />
              we give children the opportunity to read, believe, and imagine as
              they learn and grow using our books.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
