import React, { useEffect, useState } from "react";
import { getLatestEvent } from "api";


export default function EventsNotification() {
  const [data, setData] = useState(null);
  
  useEffect(() => {
    const response = getLatestEvent();
    if (response) {
      setData(response);
    } 
  }, [])
  
  if (data) {
    const eventDate = data.dates;
    const monthExp = /(?:[a-z]{3,4})(?!.*[a-z]{3,4})/gi;
    const res = monthExp.exec(eventDate);
    const month = res && res.length > 0  ? res[0].toLowerCase() : "";
    const dayYearExp = /(?:\d{1,2},* \d{4})(?!.*\d{1,2},* \d{4})/gi;
    const res2 = dayYearExp.exec(eventDate);
    const dayYearStr = res2 && res2.length > 0 ? res2[0] : "";
    const dayYear = dayYearStr.replace(/,/, '').split(" ");
    let disable = false;
    
    if (month && dayYear.length > 0) {
      const currentDate = Date.now()
      const parsedEventDate = Date.parse(`${dayYear[0]} ${month} ${dayYear[1]} 00:00:00 GMT`);

      if (parsedEventDate < currentDate) {
        disable = true;
      }
    }

    return !disable && (
      <div id="notifications" className="alert alert-primary alert-dismissible fade show alert-section" role="alert">
          <div className="container">
              <div className="row">
                  <div className="h5 text-center">Upcoming Events</div>
                  <hr/>
                  <div className="image col-12 col-lg-3">
                  <img src={data.image} className="img-thumbnail" alt={data.name} />
                  </div>
                  <div className="col text-start">
                  <div className="h6">
                      <a href={data.link} target="_blank" rel="noreferrer">
                      {data.name}
                      </a>
                  </div>
                  <dl className="dl-horizontal">
                      <dd>{data.location}</dd>
                      <dd>{data.dates}</dd>
                      <dd>{data.time}</dd>
                  </dl>
                  </div>
              </div>
          </div>
          <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
          ></button>
      </div>
    );
  }

  return null;
}