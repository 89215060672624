import React, { useEffect, useState, useRef } from "react";
import { register } from 'swiper/element/bundle';
import * as $ from "jquery";
import { getCategories, getReviews } from "api";

register();


const ReviewCategories = ({ active }) => {
  const [categories, setCategories] = useState(null);
  
  useEffect(() => {
    const response = getCategories();
    if (response) {
      setCategories(response);
    }
  }, [])

  if (!categories) return null;

  return (
    <ul id="review-tabs" className="nav nav-tabs nav-justified" role="tablist">
      {categories.map(({ name, title }) => (
        <li
          className={`nav-link${active === name ? " active" : ""}`}
          key={name}
          role="presentation"
        >
          <a name={name} href="/#reviews">{title}</a>
        </li>
      ))}
    </ul>
  );
};

const ReviewItem = ({ reviewer, review, key }) => {
  return (
    <swiper-slide key={`review_slide_${reviewer}_${key}`}>
      <div className="container col-8 col-md-8">
        <div className="item text-muted py-3">
          <p className="reviewer">{reviewer}</p>
          <p className="review">{review}</p>
        </div>
      </div>
    </swiper-slide>
  );
};

const ReviewList = ({ active }) => {
  const [reviews, setReviews] = useState(null);

  useEffect(() => {
    const response = getReviews();
    if (response) {
      setReviews(response);
    }
  }, [])

  if (!reviews) return null

  return reviews
  .filter(({ category }) => category === active)
  .map((props, idx) => ReviewItem({...props, key: idx }))
};

const ReviewSwiper = ({ active }) => {
  const swiperElRef = useRef(null);

  return (
    <swiper-container
      className={`swiper-${active}`}
      ref={swiperElRef}
      navigation="true"
    >
      <ReviewList {... { active }} />
    </swiper-container>
  );
};

export default function ReviewSection() {
  const [active, setActive] = useState('amazon_square');

  useEffect(() => {
    $("#review-tabs a").on("click", function (e) {
      e.preventDefault();
      setActive(e.target.name);
    });
  }, [active]);

  return (
    <section id="reviews" className="micro-section">
      <div className="container text-center">
        <div className="h5">
          <span>Our</span>
          &nbsp;
          <span>Reviews</span>
        </div>
        <div className="row">
          <div className="col text-muted">
            Check out what our customers are saying about us.
          </div>
        </div>
        <div className="row justify-content-center gx-5">
          <ReviewCategories {...{ active }} />
          <ReviewSwiper {...{ active }} />
        </div>
      </div>
    </section>
  );
}
