import React from "react";
import { Link } from "react-router-dom";

import BookIcon from "images/hj-logo-long.svg";
import SocialLinks from "./SocialLinks";

export default function Footer() {
  const NavLinks = [
    {
      title: "Press Releases",
      url: "/press-releases",
    },
    // {
    //   title: "Privacy Policy",
    //   url: "/privacy-policy",
    // },
    {
      title: "Contact Us",
      hash: "#contact",
    },
  ];

  return (
    <footer className="px-4 py-4 border-top">
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="my-3">
              <img src={BookIcon} alt="HJP Logo" height="43" />
            </div>
            <div className="py-3">
              <span className="text-muted">
                Henry and Jeffers is a small, but growing, family owned
                publishing house that focuses on publishing books geared towards
                children.
              </span>
            </div>
            <div className="py-3">
              <ul className="nav justify-content-center">
                {NavLinks.map((link, idx) => (
                  <li key={`nav-${idx}`} className="nav-item">
                    {link.hash ? (
                      <a
                        key={`${link.title}= ${idx}`}
                        className="nav-link"
                        data-bs-target={link.hash}
                        href={`/?${link.hash}`}
                      >
                        {link.title}
                      </a>
                    ) : (
                      <Link className="nav-link" to={link.url}>
                        {link.title}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="py-3">
              <span className="text-muted">
                &copy; 2022 Henry and Jeffers Publishing, LLC
              </span>
            </div>
            <div className="py-1">
              <SocialLinks />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
