import React, { useEffect } from "react";
import Page from "components/PageWrapper";
import aboutImg from "images/about-img@2x.png";
// import avatarImg from "images/placeholders@2x.png";
import clarissaImg from "images/team-clarissa@2x.png";
import daveImg from "images/team-dave@2x.png";
import topeImg from "images/team-tope@2x.png";

const team = [
  {
    name: "Dr. Clarissa Henry-Adekanbi",
    title: "CEO",
    bio: "Dr. Clarissa Henry-Adekanbi is a mother of three children, school psychologist, and educator. For more than two decades, she has worked with children of a variety of ages and their families, to teach children the social emotional skills they need to learn and grow. Through her books, daily interactions, and family/community partnerships, Dr. Henry fills her children and students with positive messages to nurture their self-esteem and self-confidence and teaches them the tools they need to fix small problems. Clarissa lives in Connecticut with her family.",
    image: clarissaImg
  },
  {
    name: "David Henry II",
    title: "Director of Marketing",
    bio: "Since David D. Henry II was a child, he loved discovering how things worked. He used his creativity to break things apart and create something new. David turned that passion into a small business allowing him to turn clients’ visions for home remodels into reality. As the Director of Marketing at Henry and Jeffers Publishing, David brings his passion for this work with him each day. David is also a father of three who lives in New York City. He consistently speaks positivity into his children to help them learn and grow. .",
    image: daveImg
  },
  {
    name: "Tope Adekanbi",
    title: "Creative Director",
    bio: "Ademola (Tope) Adekanbi is a senior software engineer who has worked for large companies. He also has a background in graphic design that he uses for editing. In Tope’s free time, he enjoys basketball, anime, and video games. He has three children and lives in Connecticut with his family.",
    image: topeImg
  },
];

const TeamMember = (props) => {
  const { name, title, bio, image } = props || {};
  return (
    <div className="col-12 col-md-4">
      <div className="circle-avatar">
        <img src={image} alt={name} />
      </div>
      <div className="name">{name}</div>
      <div className="title pb-4">{title}</div>
      <p className="text-start">{bio}</p>
    </div>
  );
};

export default function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <Page>
      <section id="about-us" className="page-section">
        <div className="container text-center">
          <div className="h5 ">
            <span>About Us</span>
            {` \\ `}
            <span>Henry and Jeffers Publishing</span>
          </div>
          <div className="row justify-content-md-center">
            <div className="float-img col-12 col-md-8">
              <img src={aboutImg} alt="about us" />
            </div>
            <div className="text-start text-muted">
              <p>
                Henry and Jeffers is a small, but growing, family owned
                publishing house that focuses on publishing books geared towards
                children. The name Henry and Jeffers was carefully chosen to
                honor our parents legacy and what they represented as immigrants
                to this country. Henry is our father’s last name and Jeffers is
                our mother’s maiden name.
              </p>
            </div>
            <div className="h5">
              <span>Our</span>
              {` `}
              <span>Vision and Mission</span>
            </div>
            <div className="text-start text-muted">
              <p>
                At Henry and Jeffers Publishing House, we are passionate about
                helping children grow to become their best authentic self.
                Clarissa strives to do that each day in her work as a school
                psychologist and educator. David, Ademola, and Clarissa also
                strive to achieve that goal in their role as parents.
              </p>
              <p>
                We believe that our children’s books can build capacity and
                empower children with the social emotional skills needed to be
                their best authentic self. We would like to get our books in the
                hands of children, educators, and families around the world to
                realize our mission.
              </p>
              <p>
                At Henry and Jeffers an important part of our vision is to
                provide a voice and another option for other independent authors
                wishing to publish their work. As we continue to grow we will
                seek out, mentor, and support independent authors from the start
                of the writing process to the publication phase.
              </p>
            </div>
          </div>
          <div className="row team justify-content-center gx-5">
            <div className="h5">
                <span>Meet</span>
                {` `}
                <span>Our Team</span>
            </div>
            {team.map((props, idx) => (
              <TeamMember key={idx} {...props} />
            ))}
          </div>
        </div>
      </section>
    </Page>
  );
}
