import React from "react";
import Page from "components/PageWrapper";
import TopSection from "components/home/TopSection";
import Products from "components/home/ProductSection";
import Reviews from "components/home/ReviewSection";
import Services from "components/home/ServiceSection";
import Events from "components/home/EventsSection";
import ContactSection from "components/home/ContactSection";
import EventsNotification from "components/home/EventsNotification";

export default function Home() {
  return (
    <Page id="home">

      <EventsNotification />
      <TopSection />
      <Products />
      <Services />
      <Events />
      <Reviews />
      <ContactSection />
    </Page>
  );
}
