import React, { useEffect, useState } from "react";
import { getEvents } from "api";

import placeImg from "images/about-img@2x.png";


const EventItem = (props) => {
  const { name, link, location, dates, time, image } = props || {};
  return (
    <div className="row">
      <div className="image col-12 col-lg-4 pb-3">
        <img src={image} className="img-thumbnail" alt={name} />
      </div>
      <div className="col text-start pb-3">
        <div className="h6">{name}</div>
        <dl className="dl-horizontal">
          <dt>Location</dt>
          <dd>{location}</dd>
          <dt>Dates</dt>
          <dd>{dates}</dd>
          <dt>Time</dt>
          <dd>{time}</dd>
          <dt>Event Link</dt>
          <dd>
            {link ? (
              <a href={link} target="_blank" rel="noreferrer">
                <span className="title">Click Here</span>
              </a>
            ) : null}
          </dd>
        </dl>
      </div>
    </div>
  );
};

const EventList = () => {
  const [data, setData] = useState(null);
  
  useEffect(() => {
    const response = getEvents();
    if (response) {
      setData(response);
    } 
  }, [])
  
  if (!data) return null;
  
  const sorted = data.sort((a, b) => b.id - a.id);
  return sorted.slice(0, 3).map((props, idx) => <EventItem key={idx} {...props} />);
};

export default function EventsSecton() {
  return (
    <section id="events" className="micro-section">
      <div className="container text-center">
        <div className="h5">
          <span>Our</span>
          &nbsp;
          <span>Events</span>
        </div>
        <div className="row">
          <div className="col text-muted">
            Check us out at the following local events.
          </div>
        </div>
        <div className="row justify-content-center py-5 gx-5">
          <div className="col-12 col-md-6">
            <div className="float-img">
              <img className="col-12" src={placeImg} alt="event overiew" />
            </div>
            <div className="text-start text-muted">
              <p>
                Living my dream and realizing a vision this summer. I've been
                blessed with the opportunity to work as a vendor most Friday's
                at the Farmer's Market at the Old State House in Hartford, CT. I
                am selling my books, Imani the Fixer and I'm Loving Me, along
                with other items that you will be able to purchase on my website
                soon.
              </p>
              <p>
                I've met lots of interesting people and made great connections.
                People have trusted me with their stories and I have been able
                to share some of my story with others. I have inspired others to
                pursue their dreams. What an unexpected blessing!
              </p>
            </div>
          </div>
          <div className="event-list col-12 col-md-6">
            <div className="h4 text-start pb-3">
              <span>Current Events</span>
              &nbsp;&#187;&nbsp;
              <a className="fw-normal fs-6" href="/all-events">
                View All Events
              </a>
            </div>
            <EventList />
          </div>
        </div>
      </div>
    </section>
  );
}
