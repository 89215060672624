import React from 'react';
import PageRoutes from './routes/route';

import './css/index.css';


function App() {
  return (
    <PageRoutes />
  );
}

export default App;
