import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from 'pages/Home';
import About from 'pages/About';
import PressReleases from 'pages/PressRelease';
import AllEvents from "pages/Events";
// import ComingSoon from "pages/ComingSoon";

export default function PageRoutes () {
    return (
        <Router>
            <Routes>
                <Route index path="/" element={<Home />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/all-events" element={<AllEvents />} />
                <Route path="/press-releases" element={<PressReleases />} />
                {/* <Route path="/privacy-policy" element={<Home />} /> */}
            </Routes>
        </Router>
    );
}
