// import placeHolder from "images/book-icon.svg";
import bnbooksImg from "images/bn-books.png";
import libraryImg from "images/library-image.png";
import wehaImg from "images/weha_article.png";
import linkedInImg from "images/linkedIn-image.png";
import authorImg from "images/author-image.png";
import booksImg from "images/book-launch.png";
import spruceImg from "images/spruce-streat-farmers-market.png";

const pressData = [
  {
    id: 1,
    title: "Successfully published Two Books! Available for purchase on Amazon.com",
    desc: "Imani the Fixer and I'm Loving me books are published and available for purchase on Amazon.com",
    link: null,
    image: booksImg
  },
  {
    id: 2,
    title: "Authors - Local Author Helps Children Manage Stress",
    desc: "Local Author Helps Children Manage Stress - Connecticut's Old State House",
    link: "https://wp.cga.ct.gov/osh/2022/07/07/local-author-helps-children-manage-stress/",
    image: authorImg
  },
  {
    id: 3,
    title: "LinkedIn: Summer Dream",
    desc: "Living my dream and realizing a vision this summer.",
    link: "https://www.linkedin.com/posts/clarissa-henry_imanithefixer-imlovingme-socialemotionallearning-activity-6959011192514224128-Osfy/?utm_source=linkedin_share&utm_medium=member_desktop_web",
    image: linkedInImg
  },
  {
    id: 4,
    title: "We-Ha - West Hartford Business Buzz: August 1, 2022",
    desc: "West Hartford resident Clarissa Henry-Adekanbi has started her own publishing company, Henry and Jeffers Publishing House, and their focus will be publishing children’s books that primarily focuses on social-emotional learning.",
    link: "https://we-ha.com/west-hartford-business-buzz-august-1-2022/",
    image: wehaImg
  },
  {
    id: 5,
    title: "Books Available in West Hartford Library: August 8, 2022",
    desc: "Imani the Fixer and I'm Loving me books are now available at Noah Webster Library in West Hartford",
    link: null,
    image: libraryImg
  },
  {
    id: 6,
    title: "You can now purchase Imani the fixer and I'm Loving Me Books at Barnes and Nobles",
    desc: "Imani the Fixer and I'm Loving me books are now available for purchase at Barnes and Nobles Online",
    link: null,
    image: bnbooksImg
  },
  {
    id: 7,
    title: "Spruce Street Farmers Market",
    desc: "Setup my table at the Manchester's Spurce Street Faramers Market. I met lots of people and made great connections. Thank you Manchester for welcoming me.",
    link: "https://www.facebook.com/SpruceStreetMarketCT/posts/pfbid0jJr7aXBwn7FXAikLV16Gxdi4CjBgFYiCojK7ijXiLzNWWjZca8DBtR66kVob1VEdl",
    image: spruceImg

  },
];

export const getPressReleases = () => pressData;
