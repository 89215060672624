import React from "react";

import placeHolder from "images/placeholders@2x.png";
import prodImg_itf from "images/book-cover-image-itf.png";
import prodImg_ilm from "images/book-cover-image-ilm.png";
import prodImg_tshirt from "images/merch-t-shirt.png";

const productData = [
  {
    type: "Website",
    title: "ImanitheFixer.com",
    // price: "19.99",
    rating: null,
    productImg: prodImg_itf,
    altText: "Goto Imani the Fixer Site",
    link: "https://www.imanithefixer.com/?#buy-book",
  },
  {
    type: "Website",
    title: "ImLovingMeBook.com",
    // price: "14.99",
    rating: null,
    productImg: prodImg_ilm,
    altText: "Goto I'm Loving Me Book Site",
    link: "https://www.imlovingmebook.com/?#buy-book",
  },
  {
    type: "Merchandise",
    title: "Coming Soon",
    // price: "20.00",
    rating: null,
    productImg: prodImg_tshirt,
    altText: "Goto I'm Loving Me Book Site",
    // link: "#",
  },
];

const ProductItem = (props) => {
  const { altText, type, title, price, productImg, link } = props || {};
  return (
    <div className="col-12 col-lg-3">
      <div className="item pb-3">
        <div className="details py-3">
          <div className="type">{type}</div>
          {link ? (
            <a href={link} target="_blank" rel="noreferrer">
              <span className="title">{title}</span>
            </a>
          ) : (
            <div className="title">{title}</div>
          )}
          {price && <div className="price">{`$${price}`}</div>}
        </div>
        <div className="img">
          {link ? (
            <a href={link} target="_blank" rel="noreferrer">
              <img src={productImg} alt={altText} />
            </a>
          ) : (
            <img src={productImg} alt={altText} />
          )}
        </div>
      </div>
    </div>
  );
};

const ProductsList = () => {
  return productData.map(
    (props, idx) => <ProductItem key={idx} {...props} />
  );
};

export default function ProductSection() {
  return (
    <section id="products" className="micro-section">
      <div className="container text-center">
        <div className="h5">
          <span>Our</span>
          &nbsp;
          <span>Product Sites</span>
        </div>
        <div className="row">
          <div className="col text-muted">
            Check out our growing inventory of products. You can purchase a copy
            of our books here or at the prodcut site links below.
          </div>
        </div>
        <div className="row justify-content-center py-5 gx-5">
          <ProductsList />
        </div>
      </div>
    </section>
  );
}
