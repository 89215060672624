import React from "react";
// import placeHolder from "images/placeholders@2x.png";
import authorTalkIcon from "images/author-talk.png";
import consultationIcon from "images/consultation-1.png";
import consultationIcon2 from "images/consultation-2.png";
import workshopIcon from "images/workshop.png";

const serviceData = [
  {
    name: "Author Talks",
    desc: "\"Author Talks\" - schedule a time for the authors to read their books and talk with your children.",
    image: authorTalkIcon,
  },
  {
    name: "Consultation with Organizations",
    desc: "Consultation with community organizations, preschools, schools, and other agencies to support and build the capacity of staff to implement social emotional learning programs.",
    image: consultationIcon,
  },
  {
    name: "Consultation with Families",
    desc: "Consultation with community organizations, preschools, schools, and other agencies to work in partnership with their families to support children's social emotional learning growth.",
    image: consultationIcon2,
  },
  {
    name: "Workshops",
    desc: "Facilitate workshops on specific focus areas based on the needs of your organization. Topics could be related but not limited to emotional health; social emotional learning; or diversity, equity, and inclusion.",
    image: workshopIcon,
  },
];

const ServiceItem = (props) => {
    const { name, desc, image } = props || {};
    return (
        <div className="col-xs-6 col-lg-6 row">
            <div className="image col-12 col-lg-4 pb-3">
                <img src={image} className="img-fluid" alt={name} />
            </div>
            <div className="col text-start pb-3">
                <div className="h6">{name}</div>
                <p className="text-muted text-start">{desc}</p>
            </div>
        </div>
    );
}

const ServiceList = () => {
    return serviceData.map(
        (props, idx) => <ServiceItem key={idx} {...props} />
    );
};

export default function ServiceSection() {
  return (
    <section id="services" className="micro-section">
      <div className="container text-center">
        <div className="h5">
          <span>Our</span>
          &nbsp;
          <span>Services</span>
        </div>
        <div className="row">
            <div className="col text-muted">
            Check out the services we offer. Reach out for further information and consultation.
          </div>
        </div>
        <div className="row justify-content-center py-5 gx-5">
            <ServiceList />
        </div>
      </div>
    </section>
  );
}
