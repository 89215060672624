import React, { useEffect, useState } from "react";
import Page from "components/PageWrapper";
import { getPressReleases } from "api";

const PressItem = (props) => {
  const { title, desc, link, image } = props || {};
  return (
    <div className="row">
      {image && <div className="image col-12 col-lg-2 pb-1">
        <img src={image} className="img-thumbnail" alt={title} />
      </div>}
      <div className="col text-start pb-3">
        <div className="h6">
          <a href={link} target="_blank" rel="noreferrer">
            {title}
          </a>
        </div>
        <p>{desc}</p>
      </div>
    </div>
  );
};

const PressList = () => {
  const [data, setData] = useState(null);
  
  useEffect(() => {
    const response = getPressReleases();
    if (response) {
      setData(response);
    } 
  }, [])

  if (!data) return null;

  const sorted = data.sort((a, b) => b.id - a.id);
  return sorted.map((props, idx) => <PressItem key={idx} {...props} />);
};

export default function PressRelease() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <Page>
      <section className="page-section">
        <div className="container text-center">
          <div className="h5">
            <span>Our</span>
            &nbsp;
            <span>Press Releases</span>
          </div>
          <div className="row">
            <div className="col text-center text-muted">
              <p>
              Check out some of our press and articles.
              </p>
            </div>
          </div>
          <div className="row py-3 gx-3">
            <div className="col-12">
              <PressList />
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
}
