import React, { useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import parentLogo from "images/hj-logo-long.svg";
import parentLogoSmall from "images/book-icon.svg";

export default function Header() {
  const location = useLocation();
  const activeRef = useRef('home');
  const NavLinks = [
    {
      title: "Home",
      hash: "#home",
    },
    {
      title: "Products",
      hash: "#products",
    },
    {
      title: "Services",
      hash: "#services",
    },
    {
      title: "Events",
      hash: "#events",
    },
    {
      title: "Reviews",
      hash: "#reviews",
    },
    {
      title: "Contact",
      hash: "#contact",
    },
    {
      title: "About Us",
      url: "/about-us",
    },
    {
      title: "Press Releases",
      url: "/press-releases",
    },
  ];

  if (location.hash !== "") {
    activeRef.current = location.hash.replace(/#/, "");
  }
  else if (location.pathname !== "/") {
    activeRef.current = location.pathname.replace("/", "").replace("-", " ");
  }

  console.log(`url -> ${activeRef.current} - ${JSON.stringify(location)}`)

  return (
    <div className="site-nav-header">
      <div id="page-nav" className="navigation py-2 mb-4 border-top border-bottom">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <Link className="navbar-brand" to={`/`}>
              <img
                className="parent-logo"
                src={parentLogo}
                alt="henry and jeffers publishing house parent site"
                height="40"
              />
              <img
                className="parent-logo-small"
                src={parentLogoSmall}
                alt="henry and jeffers publishing house parent site"
                height="40"
              />
            </Link>
            <div className="px-3 current-section">{activeRef.current}</div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#site-main-navbar"
              aria-controls="site-main-navbar"
              aria-expanded="false"
              aria-label="toggle mobile navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="site-main-navbar">
              <ul className="navbar-nav">
                {NavLinks.map((link, idx) => {
                  const active = activeRef.current === link.title.toLowerCase() ? ' active' : '';
                  const className = `nav-link${active}`;

                  console.log("active -", link.title, activeRef.current, active);

                  return (
                    <li key={`nav-${idx}`} className="nav-item">
                      {link.hash ? (
                        <a
                          key={`${link.title}= ${idx}`}
                          className={className}
                          data-bs-target={link.hash}
                          href={`/?${link.hash}`}
                        >
                          {link.title}
                        </a>
                      ) : (
                        <Link className={className} to={link.url}>
                          {link.title}
                        </Link>
                      )}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
