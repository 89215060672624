// import placeImg from "images/about-img@2x.png";
import farmersMarketImg from "images/farmers-market.png";
import sprucegFamilyFarm from "images/spruce-streat-farmers-market.png";
import strongFamilyFarm from "images/strong-family-farm-pic.png";
import riverBendAuthStage from "images/river-bend-author-stage.png";
import artoberImg from "images/artober.png";
import auerfarmFallFestival from "images/auerfarm_fall_festival@2x.jpg";
import NHFPLBookCoverImg from "images/imani_the_fixer_front_cover_nhfpl@2x.jpg";

const eventData = [
  {
    id: 0,
    name: "Farmers Market CT Democracy Center",
    location: "Connecticut’s Old State House - 800 Main St. Hartford ",
    dates: "Jul 8, 22, 29, and Aug 5, 19 2022",
    time: "10:00am - 2:00pm",
    image: farmersMarketImg,
    link: "https://wp.cga.ct.gov/osh/2022/07/07/local-author-helps-children-manage-stress/"
  },
  {
    id: 1,
    name: "Spruce Street Farmers Market",
    location: "Spruce Street Farmers Market - 163 Spruce Street, Manchester, CT",
    dates: "Aug 3, 10, 17, 2022",
    time: "4:30pm - 7:30pm",
    image: sprucegFamilyFarm,
    link: "https://www.facebook.com/SpruceStreetMarketCT/posts/pfbid0jJr7aXBwn7FXAikLV16Gxdi4CjBgFYiCojK7ijXiLzNWWjZca8DBtR66kVob1VEdl"
  },
  {
    id: 2,
    name: "Meet Local Authors",
    location: "The Strong Family Farm - 274 West Street, Vernon, CT",
    dates: "Aug 28, 2022",
    time: "10:00am - 2:00pm",
    image: strongFamilyFarm,
    link: "https://www.strongfamilyfarm.org/activities-events/"
  },
  {
    id:3,
    name: "River Bend Author Stage",
    location: "Hubbard Green - 99 Hubbard Street, Glastonbury, CT",
    dates: "Sept 10-11, 2022",
    time: "11:00am - 4:00pm",
    image: riverBendAuthStage,
    link: "https://www.glastonburyarts.org/on-the-green-show"
  },
  {
    id: 4,
    name: "3rd Annual Artober Fest and Craft Fair",
    location: "Veterans Memorial Park Pavilion, Windsor Locks, CT",
    dates: "Oct 1, 2022",
    time: "1:30pm - 2:00pm",
    image: artoberImg,
    link: "https://www.facebook.com/WLArtsCouncil/"
  },
  {
    id: 5,
    name: "AuerFarm Fall Festival",
    location: "AuerFarm - 158 Auer Farm Road, Bloomfield, CT",
    dates: "Oct 15, 2022",
    time: "11:00am - 4:00pm",
    image: auerfarmFallFestival,
    link: "https://auerfarm.org/2022-fall-festival/"
  },
  {
    id: 6,
    name: "Children's Authors and Illustrators Series",
    location: "Ives Main Library - 133 Elm St New Haven, CT 06510",
    dates: "Oct 22, 2022",
    time: "10:30am - 11:30am",
    image: NHFPLBookCoverImg,
    link: "https://nhfpl.libnet.info/event/7256688"
  },
];
const len = eventData.length;

export const getEvents = () => eventData;

export const getLatestEvent = () => eventData[len - 1];