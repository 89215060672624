const categories = [
  {
    title: "Amazon / Square Reviews",
    name: "amazon_square",
  },
  {
    title: "Vendor Events Reviews",
    name: 'vendor'
  },
  {
    title: "Educators Reviews",
    name: "educator",
  },
  {
    title: "Workshop Reviews",
    name: "workshop",
  },
];

const reviewData = [
  {
    category: "amazon_square",
    reviewer: "Imani the Fixer Verified Purchase ",
    review: "Imani the Fixer is a thoughtfully written and beautifully illustrated children's book that provides an age appropriate problem solving strategy for school age children. As an educator and parent, having a hands on resource that provides explicit steps for teaching a problem solving strategy is useful for daycare, school and home environments. In addition, the illustrations and names in this book allow for children of many cultural backgrounds to be represented. I bought this book for my nieces and nephews as well as friends who teach in daycare and elementary schools. Looking forward to sharing it with others as well!",
  },
  {
    category: "amazon_square",
    reviewer: "I’m Loving Me Verified Purchase",
    review: "This little book packs quite a wallop! The subject matter is so very relevant to children of all ages, races, nationalities, religions, and temperaments. And the story is delivered in a sweet, easy to digest format, so that the message comes across clearly - even a toddler would understand! The preface, by Dr. Henry-Adekanbi, clearly shows the authors' understanding of the role self-esteem plays in a child's development, and how adults are responsible for fostering this quality in children. So parents can be assured they are getting a learned professional perspective. And it is obvious the illustrations were selected to promote individuality and equity, regardless of what kind of \"animal\" you are. These cute drawings show children in every setting, and the book gives a repertoire of stories and adventures that any age group can relate to. What I love most though, is that while it gives an enormously important lesson, the authors still manage to capture the readers' attention in a fun, cute, and entertaining way. Hurry up and buy this for the young loved ones in your life!",
  },
  {
    category: "amazon_square",
    reviewer: "Square Customer feedback",
    review: "Dr. Henry-Adekanbi has created a worthy product based on guiding self-esteem prompts, in an accessible and appealing format for children (& adults) of all ages. Thank you for the quality and for being in downtown Hartford today!",
  },
  {
    category: "vendor",
    reviewer: "Vendor Events Review",
    review: "A customer reported that she read Imani the Fixer to her four and my seven year old. After two days, her four year old daughter used the strategies to stay calm as she fixed her small problems with coaching from her mom.",
  },
  {
    category: "educator",
    reviewer: "First Grade Teacher",
    review: "This book was amazing. My students were really into Imani the Fixer. The students who struggled more with their behavior enjoyed the story and started to learn the steps after reading it just one time.",
  },
  {
    category: "educator",
    reviewer: "Social Worker",
    review: "Wonderful concept- fantastic steps! Would love for all school mental health providers to have a copy on hand for kids and parents to borrow :)",
  },
  {
    category: "educator",
    reviewer: "Social Worker",
    review: "You are amazing, Clarissa!!!  I love reading Imani the Fixer with students and discussing the 5 S's as a tool that they can use for problem solving!!  I can not wait to read I'm Loving Me!!  I'm telling you- we are going to be seeing you on the Today Show one of these days!!! :)",
  },
  {
    category: "educator",
    reviewer: "School Psychologist",
    review: "That is amazing! Congratulations on the books! They look great with a strong positive message for kids and parents. You have a gift, keep sharing it!",
  },
  {
    category: "workshop",
    reviewer: "Five S’s Workshop reviews",
    review: <>Things participants enjoyed the most included:
    <ul>
      <li>Useful ways to identify how to interact with children and their feelings.</li>
      <li>I appreciated the steps of helping children identify their emotions.</li>
      <li>I enjoyed the way she broke everything down so I understood.</li>
      <li>I enjoyed learning how to respond to children’s emotions, also role playing</li>
    </ul></>
  },
];

export const getCategories = () => categories;
export const getReviews = () => reviewData;